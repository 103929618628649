<template>

  <div
    class="vue-speech-recognition"
    :class="{
      error: error,
      active: isRecording
    }"
    @click="start"
  >
    <span>

    </span>
    <!-- <hr/>
    <hr/>
    <button
        @click="toggleRecording"
        class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {{ isRecording ? 'Stop Recording' : 'Record' }}
      </button> -->
  </div>
</template>

<script>

function formatTranscript(transcriptJson) {
  const transcript = transcriptJson
  const formattedTranscript = []
  let currentSpeaker = null
  let currentSegment = []
  let segmentStartTime = null
  let segmentEndTime = null

  transcript.forEach((item) => {
    const alternative = item.alternatives[0]
    const speaker = alternative.speaker
    const content = alternative.content
    const startTime = item.start_time
    const endTime = item.end_time

    if (speaker !== currentSpeaker) {
      if (currentSegment.length > 0) {
        formattedTranscript.push({
          startTime: segmentStartTime,
          endTime: segmentEndTime,
          speaker: currentSpeaker,
          content: currentSegment.join(' ')
        })
      }
      currentSpeaker = speaker
      currentSegment = []
      segmentStartTime = startTime
    }

    currentSegment.push(content)
    segmentEndTime = endTime
  })

  if (currentSegment.length > 0) {
    formattedTranscript.push({
      startTime: segmentStartTime,
      endTime: segmentEndTime,
      speaker: currentSpeaker,
      content: currentSegment.join(' ')
    })
  }

  return formattedTranscript
  // return formattedTranscript
  //   .map((segment) => {
  //     const start = new Date(segment.startTime * 1000).toISOString().substr(11, 8)
  //     const end = new Date(segment.endTime * 1000).toISOString().substr(11, 8)
  //     return `${start} - ${end} : Speaker : ${segment.speaker} : "${segment.content}"`
  //   })
  //   .join('\n')
}

export default {
  name: 'RealTimeVoiceCapture',
  props: {
    vocabSettings: {
      type: Object,
      required: false,
      default: () => ({
        boost_list: [],
        custom_list: []
      })
    },
    lang: {
      type: String
    },
    deviceId: {
      type: String
    }
  },
  data: () => ({
    mediaRecorder: null,
    isRecEnded: false,
    sessionStarted: false,

    sessionInfo: null,
    speechMaticsKey: 'Yc5R4AkoO0lUdCz2o4bdiqPXapEVJkQ4',
    devices: [],
    selectedDevice: '',
    audioStream: null,
    sequenceNumber: 0,
    socket: null,
    SAMPLE_RATE: 48000,
    // sample_rate: 16000,//48000
    showResult: false,
    finals: {},
    partials: '',
    mediaStream: {},
    recorder: {},
    totalAudioSize: 0,
    error: null,
    isRecording: false,
    audioChunks: [],
    scriptsLoaded: {
      assemblyai: false,
      recordRTC: false
    },
    transcriptionConfig: {
      message: 'StartRecognition',

      audio_format: {
        type: 'file'
        // encoding: 'pcm_f32le',
        // sample_rate: 16000
      }

    },
    token: null // AssemblyAI token,

  }),
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  created() {
    this.audioChunks.push([])
  },

  beforeRouteLeave(to, from, next) {
    if (this.confirmStayInRecording()) {
      next(false)
    } else {
      next()
    }
  },
  async mounted() {
    // Automatically load the script and initialize AssemblyAI
    try {
      await this.loadDependencies()
    } catch (error) {
      console.error('Error during initialization:', error)
    }

    // this.listAudioDevices()
  },
  methods: {

    async listAudioDevices() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.devices = devices.filter((d) => d.kind === 'audioinput')
        if (!this.devices.length) alert('No audio input devices found.')
      } catch (err) {
        console.error('Error listing audio devices:', err)
      }
    },
    async initiateSession() {
      if (this.sessionStarted) return Promise.resolve(this.sessionInfo)
      const tokenUrl = 'https://mp.speechmatics.com/v1/api_keys?type=rt'
      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.speechMaticsKey
        },
        body: JSON.stringify({ ttl: 3600 })
      })

      if (!response.ok) {
        const errorText = await response.text()
        this.$emit('sessionInitiationError', errorText)
        throw new Error(`${response.status}: ${await response.text()}`)
      } else {
        this.sessionStarted = true
      }
      return response.json()
    },

    async setupWebSocket() {
      const wsUrl = 'wss://eu2.rt.speechmatics.com/v2?jwt='
      const tokenDetails = await this.initiateSession()
      const id = Date.now()
      this.$emit('sessionInitiated', { sessionId: id })
      const url = wsUrl + tokenDetails.key_value
      this.sessionInfo = { url, id }
      this.$emit('sessionInitiated', { sessionId: id })
      this.socket = new WebSocket(url)
      this.socket.onopen = () => {
        console.log('WebSocket connected')
        this.socket.send(JSON.stringify(this.transcriptionConfig))
      }
      this.socket.onclose = () => {
        console.log('WebSocket closed')
      }
      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error)
      }
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        console.log(data.message)
        switch (data.message) {
          case 'AudioAdded':
            this.sequenceNumber = data.seq_no
            break
          case 'AddTranscript':
            if (data.metadata?.transcript) {
              // this.finals[Date.now * 1000] = message.data.utterance.text
              const formatted = formatTranscript(data.results)
              formatted.forEach(tx => {
                this.finals[tx.startTime * 1000] = { s: tx.speaker, c: tx.content }
              })
              // /startTime: 15.62, endTime: 15.62, speaker: 'S1', content: '.
              console.log(formatted)
              // const tscript = data.results.map((r) => r.alternatives?.[0].content).join(' ')
              // this.finals = {}
              this.$emit('transcriptionText', JSON.stringify(this.finals))
              // displayMessage(data.metadata.transcript);
              // completeTranscript.push(data.metadata.transcript);
            }
            break
          case 'RecognitionFinished':
            console.log('RecognitionFinished')
            // processFinalTranscript();
            break
          case 'EndOfTranscript':
            console.log(event)
            if (this.socket) {
              if (this.audioStream) {
                this.audioStream.getTracks().forEach((track) => track.stop())
                this.audioStream = null
                this.mediaRecorder = null
                this.isRecEnded = true
                this.isRecording = false
              }
              this.socket.close()
            }
            break
        }
      }
    },

    async  setupMediaRecorder() {
      try {
        const micStream = await navigator.mediaDevices.getUserMedia({
          audio: true
        })

        const options = {
          mimeType: MediaRecorder.isTypeSupported('audio/webm')
            ? 'audio/webm'
            : 'audio/mp4'
        }

        this.mediaRecorder = new MediaRecorder(micStream, options)

        this.mediaRecorder.ondataavailable = (event) => {
          if (
            event.data.size > 0 &&
                this.socket &&
                this.socket.readyState === WebSocket.OPEN // &&this.isRecording
          ) {
            this.socket.send(event.data)
          }
        }

        this.mediaRecorder.onstop = () => {
          micStream.getTracks().forEach((track) => track.stop())
        }

        return Promise.resolve(this.mediaRecorder)
      } catch (error) {
        console.error('Error setting up MediaRecorder:', error)
        throw error
      }
    },

    async startRecording() {
      try {
        this.transcriptionConfig.transcription_config = {
          additional_vocab: [
            ...this.vocabSettings.boost_list.map(p => ({ content: p })),
            ...this.vocabSettings.custom_list
          ],
          language: 'en',
          diarization: 'speaker',
          operating_point: 'enhanced',
          enable_entities: true,
          speaker_diarization_config: { max_speakers: 10 },
          max_delay: 1,
          max_delay_mode: 'flexible'
        }

        console.log(this.transcriptionConfig)

        this.transcriptionConfig.transcription_config.language = this.lang.split('-')[0]
        if (this.lang !== 'pl') {
          delete this.transcriptionConfig.transcription_config.additional_vocab
        }
        this.isRecEnded = false
        this.showResult = true
        this.finals = {}
        this.partials = '...'

        this.audioStream = await navigator.mediaDevices.getUserMedia({
          audio: this.deviceId ? { deviceId: { exact: this.deviceId } } : true
          // audio: true
        })
        // audio/webm
        // this.recorder = new window.RecordRTC(this.audioStream, {
        //   type: 'audio',
        //   mimeType: 'audio/webm',
        //   recorderType: window.StereoAudioRecorder,
        //   timeSlice: 1000,
        //   ondataavailable: async (blob) => {
        //     const buffer = await blob.arrayBuffer()
        //     if (this.socket?.readyState === WebSocket.OPEN) {
        //       this.socket.send(buffer.slice(44)) // Remove WAV header
        //     }
        //   },
        //   sampleRate: this.SAMPLE_RATE,
        //   desiredSampRate: this.SAMPLE_RATE,
        //   numberOfAudioChannels: 1
        // })
        await this.setupWebSocket()
        await this.setupMediaRecorder()
        this.mediaRecorder.start(1000)
      } catch (err) {
        console.error('Error starting recording:', err)
        this.stopRecording()
      }
    },
    stopRecording() {
      // this.isRecording = false
      this.isRecEnded = true
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop()
        this.mediaRecorder = null
      }

      if (this.socket?.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ message: 'EndOfStream',
          last_seq_no: this.sequenceNumber }))
      }
      console.log('Stopping...')
      // eslint-disable-next-line no-unused-expressions
      // this.recorder?.destroy()
      // eslint-disable-next-line no-unused-expressions
      if (this.socket?.readyState === WebSocket.OPEN) {
        // this.socket.close(4500)
      }
      if (this.audioStream) {
        this.audioStream.getTracks().forEach((track) => track.stop())
      }
      // eslint-disable-next-line no-unused-expressions

      this.sessionStarted = false
      this.sessionInfo = null
      this.$emit('stopped')
      setTimeout(() => {
        this.$emit('sessionCompleted', { sessionId: this.sessionInfo?.id, text: this.finals })
      }, 2500)
    },

    start() {
      this.toggleRecording()
      // if (this.isRecording) {
      //   this.stopRecording()
      //   return
      // }
      // this.startRecording()
    },
    loadScript(url, key) {
      return new Promise((resolve, reject) => {
        if (this.scriptsLoaded[key]) {
          resolve() // Script already loaded
          return
        }
        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.onload = () => {
          this.scriptsLoaded[key] = true
          resolve()
        }
        script.onerror = () =>
          reject(new Error(`Failed to load ${key} script.`))
        document.head.appendChild(script)
      })
    },
    async loadDependencies() {
      try {
        // Load AssemblyAI and RecordRTC scripts dynamically

        await this.loadScript(
          'https://www.WebRTC-Experiment.com/RecordRTC.js',
          'recordRTC'
        )
      } catch (error) {
        console.error('Error loading dependencies:', error)
        throw error // Ensure initialization halts if a dependency fails
      }
    },

    async toggleRecording() {
      if (this.isRecording) {
        await this.stopRecording()
      } else {
        await this.startRecording()
      }
      this.isRecording = !this.isRecording
    }

  }
}
</script>

<style lang="scss">
.vue-speech-recognition {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

.vue-speech-recognition-mini {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  }

  to {
    box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    background-color: #e53935;
    transform: scale(0.9);
  }
}
</style>
