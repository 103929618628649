<template>
  <b-modal ref="pres-modal" :visible="open" @hide="$emit('close')" size="xl" scrollable @ok="sendPrescription($event)">
    <template v-slot:modal-title>
      <b-row align-h="between" class="head-container">
        <b-col md="4" align-self="center">
          <b-row>
            <b-col md="10">
              <h5>{{ $t('visit.addPrescription') }}</h5>
            </b-col>
            <b-col class="pr-0 head-arrows">
              <p class="text-right h4">
                <b-icon class="cursor-point" icon="arrow-right-circle-fill" v-if="blockFullView" variant="secondary"
                  @click="toggleSidebar"></b-icon>
                <b-icon class="cursor-point" icon="arrow-left-circle-fill" v-if="!blockFullView" variant="secondary"
                  @click="toggleSidebar"></b-icon>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8" class="text-right">
          <b-row align-v="center">
            <b-col md="1" class="custom-check">
              <b-form-checkbox v-model="withPayment" @change="changePayment"
                :style="{ color: patient.ewus === 1 ? 'green' : 'red' }">
                {{ $t('visit.onlyRefunded') }}
              </b-form-checkbox>
            </b-col>
            <b-col md="11">
              <vue-tags-input v-model="tag" v-on:ready="childReady = true" ref="tagInput"
                :autocomplete-items="autocompleteItems" :placeholder="placeholderText"
                :add-only-from-autocomplete="true" @before-adding-tag="checkTag">
                <div slot="autocomplete-item" slot-scope="props" class="my-item">
                  <b-card no-body v-if="props.item.matchedArray.length !== 0">
                    <b-card-header header-tag="header" class="p-1 med-search-header" role="tab" style="text-align: left"
                      :class="{ 'type2': props.item.groupType == 2 }">
                      <b-row>
                        <b-col md="12" v-b-toggle="'med-search-' + props.index" class="pl-3">
                          <span class="when-closed plus-icon">+ </span>
                          <span class="when-opened minus-icon">- </span>
                          <span class="pl-2 mr-2">{{ props.item.text }} <span class="text-info pl-0">({{
                            props.item.matchedArray.length }} leków)</span></span>
                          <span>
                            <b-button variant="primary" class="m-0" size="sm"
                              v-if="props.item.matchedArray.length === 1"
                              @click="addMedicineToPres(props.item.matchedArray[0])" style="font-size:11px">
                              {{ $t('visit.addThisBtn') }}
                            </b-button>
                          </span>
                        </b-col>
                      </b-row>
                    </b-card-header>
                    <b-collapse :id="'med-search-' + props.index" accordion="my-accordion" role="tabpanel">
                      <b-card-body>
                        <b-card-text>
                          <b-row align-v="center" v-for="(item, index) in props.item.matchedArray"
                            :key="'search' + index" class="med-row">
                            <b-col class="pl-2 p-0">
                              <!-- @click="props.performAdd(item)" -->
                              <span class="d-inline-block p-1 pl-3 pr-2 mr-2" @click="addMedicineToPres(item)">{{
                                item.medicineName.replace(item.strength, '') }} - {{ item.strength }} | {{
                                  item.packageSize
                                }} {{ item.unit }} <span class="block"> R: {{
                                  Array.from(new Set(item.taksacja.map(k => {
                                    const perc = parseFloat(k.refundation);
                                    return k.refundation == 'R' ? k.refundation : (perc >= 1 ? perc : perc * 100) + '%';
                                  }))).join(', ')
                                }}</span>
                                <span v-if="item.price" class="sub-text p-0"><br /> Price: {{ item.price }}</span>
                                <br />
                                <div class="sub-text comp p-0"><u>Composition:</u></div>
                                {{
                                  parseMedsData({
                                    substances: item.activeSubstances[0].name, strengths: item.strength
                                  }).perUnit
                                }}
                                <div class="sub-text p-0"
                                  v-for="(compItem, compIndex) in parseMedsData({ substances: item.activeSubstances[0].name, strengths: item.strength }).comps"
                                  :key="'comp' + compIndex">{{
                                    compItem.composition }}
                                </div>

                                <!-- <div class="sub-text p-0" v-for="(compItem, compIndex) in item.activeSubstances"
                                  :key="'comp' + compIndex">{{
                                    compItem.name }} - {{ item.strength }}
                                    {{ parseMedsData({substances:compItem.name,strengths:item.strength }) }}
                                    </div> -->

                              </span>
                            </b-col>
                            <b-col md="3" class="text-left pl-2 med-actions">
                              <b-button variant="transparent" class="p-0" style="vertical-align:top;margin-top:0px;"
                                @click="showMedInfo(item)">
                                <span class="custom-icon">i</span>
                                <!-- <b-icon icon="info-circle-fill" scale="1" variant="info" class="med-info"
                                  @click="showMedInfo(item)"></b-icon> -->
                              </b-button>
                              <b-button variant="transparent" class="p-0" style="vertical-align:top;margin-top:0px;"
                                @click="addMedicineToPres(item)">
                                <span class="custom-icon"> &#43;</span>
                                <!-- <b-icon icon="plus-circle-fill" scale="1" variant="info"
                                  @click="addMedicineToPres(item)"></b-icon> -->
                              </b-button>
                              <b-button variant="transparent" class="p-0" style="vertical-align:top;margin-top:0px;"
                                @click="addToDoctorFavourites(item)">
                                <span class="custom-icon star"> &#9733;</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </vue-tags-input>
              <div v-if="tag !== ''" :class="`${medResultSuccess ? 'green circle' : 'circle orange pulse'}`"></div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <div class="trans-container">
      <b-row class="ml-2 mr-2">
        <b-col md="12" sm="12"> </b-col>
        <!-- first block -->
        <b-col :md="firstBlockSize" sm="12" class="trans-first-block pl-0">
          <div class="info-box" v-if="showInfo">
            <div class="info-header p-2 pr-1">
              <b-row class="pr-2" align-h="between">
                <b-col>
                  <h6>{{ $t('visit.infoPopUpDetails.infoPopupTitle') }}</h6>
                </b-col>
                <b-col cols="2" class="text-right">
                  <b-icon icon="x" scale="1.5" variant="secondary" style="cursor: pointer" @click="closeInfo"></b-icon>
                </b-col>
              </b-row>
            </div>
            <div class="p-3 med-description" v-bind:class="[
              Object.keys(medInfo).length === 0 ? 'hide-info' : 'show-info'
            ]">
              <b-row>
                <b-col md="12">
                  <b>{{ medInfo.medicineName }}</b>,
                  <span class="content-block">{{ medInfo.manufacturer }}</span>
                  <div>
                    <span class="title">{{ $t('visit.infoPopUpDetails.dose') }}: </span>
                    <span class="content-block">{{ medInfo.strength }}</span>
                  </div>
                  <div>
                    <span class="title">{{ $t('visit.infoPopUpDetails.package') }}: </span>
                    <span class="content-block">{{ medInfo.packageSize }} tablets</span>
                  </div>
                  <div>
                    <span class="title">{{ $t('visit.infoPopUpDetails.price') }}: PLN</span>
                    <span class="content-block">{{ medInfo.price }}</span>
                  </div>
                  <div><span class="title">{{ $t('visit.infoPopUpDetails.availableRefunds') }}</span></div>
                  <ul>
                    <li class="content-block" v-for="(item, index) in medInfo.taksacja" :key="'desc' + index">
                      <div v-if="Object.keys(item).includes('description')">
                        {{ item.refundation }} - {{ item.description }}
                      </div>
                      <div v-else>{{ item.refundation }}</div>
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-row :class="{ 'hide-block': firstBlockSize == 0 }">
            <b-col md="12" sm="12">
              <b-row if="patient">
                <b-col cols="12">
                  <table class="prescription-table">
                    <tr>
                      <td class="sub-head">{{ $t('visit.addPatient') }}</td>
                      <td>
                        {{ patient.firstName }}
                        {{ patient.lastName }}
                      </td>
                    </tr>
                    <tr>
                      <td class="sub-head">PESEL</td>
                      <td>{{ patient.pesel }}</td>
                    </tr>
                    <tr>
                      <td class="sub-head">{{ $t('visit.address') }}</td>
                      <td>
                        {{ patient.address }}
                        <br />
                        {{ patient.city }},
                      </td>
                    </tr>
                    <tr>
                      <td>Teryt:</td>
                      <td>
                        <span v-if="patient.teryt">{{ patient.teryt }}</span><b-form-input v-model="prescription.teryt"
                          :state="prescription.teryt" v-else />
                      </td>
                    </tr>

                    <tr>
                      <td class="sub-head">{{ $t('visit.isInsuranced') }}?</td>
                      <td :style="{ color: patient.ewus === 1 ? 'green' : 'red' }">
                        {{ patient.ewus === 1 ? 'Tak' : 'Nie' }}
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="12" sm="12" class="mt-4">
              <h6 class="sub-head">
                {{ $t('visit.patientPastPrescription') }}
              </h6>
              <h5>
                <b-button v-if="prevMedicines.length !== 0 && selectedMedicines.length !== 0" variant="secondary"
                  @click="includeSelectedMedToPres()">Add to Recepty</b-button>
              </h5>
              <div v-if="prevMedicines.length === 0" class="pt-2">
                <b-alert show variant="secondary">{{
                  $t('visit.noPastPrescriptions')
                  }}</b-alert>
              </div>
              <div class="accordion mb-1 mt-4" role="tablist" v-if="prevMedicines">
                <b-card no-body v-for="(item, index) in prevMedicines" :key="'prev' + index">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-row class="p-0" v-b-toggle="'prev-med-' + index">
                      <b-col md="9" class="text-left">
                        <b-button class="small text-left" variant="transparent"><span class="text-info pl-0">({{
                          item.medicines.length }} leków)</span> -
                          {{ item.date | formatDate }}</b-button>

                      </b-col>
                      <b-col class="text-right">
                        <b-button variant="transparent" @click="reuseMedicine(item.medicines)">
                          <font-awesome-icon icon="redo"></font-awesome-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse :id="'prev-med-' + index" role="tabpanel">
                    <b-card-body>
                      <b> PIN : <span>{{ item.ww_pin }}</span> </b>
                      <b-card-text>
                        <b-form-group label="" v-slot="{ ariaDescribedby }">
                          <!-- {{ selectedMedicines }} -->
                          <!-- {{ item.medicines }} -->
                          <b-form-checkbox-group v-model="selectedMedicines" :options="item.medicines"
                            value-field="keyValue" text-field="displayText" :aria-describedby="ariaDescribedby"
                            name="flavour-2a" stacked></b-form-checkbox-group>
                        </b-form-group>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col :md="secondBlockSize" sm="12" class="pl-3 pr-0">
          <div>
            <b-row align-v="center" align="right">
              <b-col md="7" sm="12" align-v="center" class="switch-control">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group v-model="selectedFav" :options="favOptions" @change="changeFavourites($event)"
                    :aria-describedby="ariaDescribedby" switches></b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col md="5" sm="12">
                <!-- <h6 class="sub-head" v-if="selectedFav[selectedFav.length-1] == 'doctorFav'">Doctor Favourites</h6> -->
                <b-input-group size="sm" v-if="selectedFav[selectedFav.length - 1] == 'doctorFav'">
                  <multiselect class="vue-multiselect" :show-labels="false" v-model="selectedDoctorFav"
                    placeholder="Select Medicine" :options="doctorFavOptions" track-by="name" label="name"
                    :close-on-select="false" :showNoOptions="false">
                    <template slot="option" slot-scope="props">
                      <div>
                        <span>
                          <!-- <b-icon-star @click="addToDoctorFavourites(props.option)">
                        </b-icon-star> -->
                          <b-icon-star-fill @click="removeFromDoctorFavorites(props.option)"
                            variant="warning"></b-icon-star-fill>
                          <span class="pl-1" @click="addMedToPresByEan(props.option.ean, null)">{{ props.option.name
                            }}</span>
                          <span class="text-right" style="float: right"
                            @click="addMedToPresByEan(props.option.ean, null)">
                            <b-icon icon="plus-circle-fill" scale="1.4" variant="outline-primary"></b-icon>
                          </span>
                        </span>
                      </div>
                    </template>
                  </multiselect>
                  <br />
                </b-input-group>
                <!-- <h6 class="sub-head" v-if="selectedFav[selectedFav.length-1] == 'patientFav'">Patient Favourites</h6> -->
                <b-input-group size="sm" v-if="selectedFav[selectedFav.length - 1] == 'patientFav'">
                  <multiselect class="vue-multiselect" :show-labels="false" v-model="selectedPatientFav"
                    placeholder="Select Medicine" :options="patientFavOptions" track-by="ean" label="name"
                    :close-on-select="false" :showNoOptions="false">
                    <template slot="option" slot-scope="props">
                      <span>
                        <span class="text-right" style="float: right; display:inline-block; width:10%"
                          @click="addMedToPresByEan(props.option.ean, null)">
                          <b-icon icon="plus-circle-fill" scale="1.4" variant="outline-primary"
                            class="m-0 m-0"></b-icon>
                        </span>
                        <span @click="addMedToPresByEan(props.option.ean, null)" class="font-weight-bold cursor-point"
                          style="float: right; display:inline-block; width:90%">{{ props.option.name }}</span> <span
                          style="display:block;width:90%"> {{
                            props.option.issue_date | formatDate }}</span>
                      </span>
                    </template>
                  </multiselect>
                  <br />
                </b-input-group>
              </b-col>
              <b-col md="12" class="seperator-border p-1 mt-1"></b-col>
            </b-row>

            <div v-if="prescriptionPackages[0].medicaments.length === 0">
              <b-alert show variant="secondary">{{ $t('visit.presInfoText') }}</b-alert>
            </div>
            <b-overlay :show="showProgress" rounded="sm">
              <b-row class="pt-2 pb-2" v-if="prescriptionPackages[0].medicaments.length !== 0">
                <b-col md="3">
                  <b-form-group :label="$t('visit.prescription365')">
                    <b-form-checkbox v-model="prescriptionJson.is365"></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="$t('visit.rightsOptions')">
                    <b-form-select :options="rightsOptions" placeholder="Dodatkowe uprawnienia">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col v-if="!prescriptionJson.is365">
                  <span>{{ $t('visit.validDateFrom') }}</span>
                  <span><b-form-datepicker size="sm" :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short'
                  }" :start-weekday="1" :min="today" v-model="prescriptionJson.dateFrom"
                      :placeholder="$t('visit.dateFrom')"></b-form-datepicker></span>
                </b-col>
                <b-col>
                  <span>{{ $t('visit.prescriptionType') }}</span>
                  <span><b-form-select v-model="prescriptionJson.prescriptionType"
                      :options="prescriptionTypes"></b-form-select></span>
                </b-col>
                <b-col v-if="showPasswordInput">
                  <span>{{ $t('visit.certPassword') }}</span>
                  <span> <b-form-input type="password" v-model="prescriptionJson.password"></b-form-input></span>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="ml-1" v-if="prescriptionPackages[0].medicaments.length !== 0">
                  <b>Leki</b>
                </b-col>
                <b-col class="text-right  ml-1 expand-collapse-block"
                  v-if="prescriptionPackages[0].medicaments.length !== 0">
                  <span class="font-weight-bold cursor-point" v-if="!expandMed" @click="togglePres()">Expand All</span>
                  <span class="font-weight-bold cursor-point" v-if="expandMed" @click="togglePres()">Collapse All</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" sm="12" v-for="(item, index) in this.prescriptionPackages" :key="'pres' + index"
                  class="p-0">
                  <b-card class="pres-header  p-0 m-0" v-if="item.medicaments.length != 0">
                    <b-card-header header-tag="header" class="pl-3 pt-2 pb-2" role="tab"
                      v-bind:class="(item.medicaments[0].strong) ? 'strong-med-bg' : ''">
                      <b-row align-v="center">
                        <b-col class="text-left pres-title font-weight-500">
                          {{ item.name }} | {{ item.medicaments.map(m => m.medicineName).join(', ') }}
                        </b-col>
                        <b-col class="text-right pr-1">
                          <span class="text-secondary font-weight-bold pr-3">{{ item.medicaments.length }} / <span
                              v-if="!item.medicaments[0].strong">{{
                                maxMedicinePerPrescription }}</span><span
                              v-if="item.medicaments[0].strong">1</span></span>
                          <span class="text-right"
                            v-if="prescStatusList.length !== 0 && prescStatusList[index] !== undefined">
                            <b-button size="sm" v-if="!prescStatusList[index].hasError" variant="primary"
                              @click="handleViewDetails(prescStatusList[index].receptyId)"
                              class="mr-3 pl-1.1 pr-1.1 pt-0 pb-0">
                              <font-awesome-icon icon="file-prescription" />
                            </b-button>
                            <b-icon v-if="!prescStatusList[index].hasError" icon="check-circle-fill" variant="success"
                              font-scale="1.3" class="mr-2 check-icon"></b-icon>
                            <b-icon v-if="prescStatusList[index].hasError === true" icon="exclamation-circle-fill"
                              font-scale="1.3" variant="danger" v-b-modal.error-modal
                              class="mr-2 cursor-point check-icon"></b-icon>
                            <b-modal ref="error-modal" id="error-modal" centered title="Error!"
                              v-if="prescStatusList[index].hasError === true">
                              <p class="my-2">{{ prescStatusList[index].userMessage }}</p>
                              <p class="mt-1">{{ prescStatusList[index].errorDetail }}</p>
                              <template #modal-footer>
                                <b-row>
                                  <b-col class="text-right">
                                    <b-button variant="primary" @click="$bvModal.hide('error-modal')">ok</b-button>
                                    <b-button variant="warning" class="ml-2" @click="retryPres(index)">Retry</b-button>
                                  </b-col>
                                </b-row>
                              </template>
                            </b-modal>
                          </span>
                        </b-col>
                      </b-row>
                    </b-card-header>
                    <b-card-text v-bind:class="(item.medicaments[0].strong) ? 'strong-med' : ''">
                      <div class="accordion" role="tablist">
                        <b-card no-body class="pres-sub-header" v-for="(medItem, medIndex) in item.medicaments"
                          :key="'med' + index + medIndex">
                          <b-card-header header-tag="header" class="pl-3 pt-1 pb-1" role="tab">
                            <b-row>
                              <b-col md="10" class="text-left p-0" v-b-toggle="'accordion-' + index + medIndex">
                                <b-button variant="transparent" class="text-left">
                                  <span>{{ medItem.medicineName }}</span><span> -
                                    {{ medItem.packageSize }}</span>
                                  <span v-if="medItem.unit"> - {{ medItem.unit }}</span>
                                  <span v-if="medItem.strength"> - {{ medItem.strength }}</span>
                                  <span> | {{ parsePayment(medItem) }}, {{ medItem.quantity }} paczki</span>
                                </b-button>
                              </b-col>
                              <b-col md="2" class="text-right">
                                <b-button variant="transparent" class="p-0">
                                  <b-icon icon="info-circle-fill" scale="1" variant="info" class="info mr-1"
                                    @click="showMedInfo(medItem)"></b-icon>
                                </b-button>
                                <b-button variant="transparent" class="pl-3 pr-3 p-0" @click="deleteMed(medItem)"
                                  :disabled=disableItems.disableClose>
                                  <b-icon icon="x" scale="1.2" variant="danger" class="close-icon"></b-icon>
                                </b-button>
                                <b-button v-b-toggle="'accordion-' + index + medIndex" variant="transparent"
                                  class="p-0 accor-actions">
                                  <span class="when-opened">
                                    <font-awesome-icon icon="chevron-up" />
                                  </span>
                                  <span class="when-closed">
                                    <font-awesome-icon icon="chevron-down" />
                                  </span>
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <b-collapse :id="'accordion-' + index + medIndex" role="tabpanel" v-model="medItem.collapse">
                            <b-card-body>
                              <b-card-text>
                                <b-row>
                                  <b-col md="12" class="mt-0">
                                    <div class="med-name text-left mt-0">
                                      <b>{{ medItem.medicineName }}</b> -
                                      {{ medItem.manufacturer }}
                                      {{ medItem.packageSize }} -
                                      {{ medItem.unit }} | {{ medItem.strength }}
                                    </div>
                                    <div>
                                      <b-row>
                                        <b-col md="12" class="text-left mt-0">
                                          <div class="sub-head">
                                            {{ $t('visit.quantity') }}
                                          </div>
                                        </b-col>
                                        <b-col md="12" class="mt-0 text-left">
                                          <b-form-group>
                                            <b-form-radio-group @change="OnChange(medItem)"
                                              :label="$t('visit.quantity')" v-model="medItem.quantity">
                                              <b-form-radio v-for="el in quantity" :key="'quantity' + el" :value="el"
                                                :disabled="disableItems.disableQty">{{
                                                  el
                                                }}</b-form-radio>
                                            </b-form-radio-group>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col md="12" class="text-left mt-0">
                                          <div class="sub-head">
                                            {{ $t('visit.suggestedDose') }}
                                          </div>
                                        </b-col>
                                        <b-col md="12" class="mt-0 text-left">
                                          <b-form-group>
                                            <b-form-input v-model="medItem.dose" />
                                            <b-form-radio-group @change="OnChange(medItem)"
                                              :label="$t('visit.suggestedDose')" v-model="medItem.dose">
                                              <b-form-radio v-for="(el, index) in doses" :key="'suggestedDose' + index"
                                                :value="el" :disabled="disableItems.disableDose">{{ el
                                                }}</b-form-radio>
                                            </b-form-radio-group>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col md="12" class="text-left mt-0">
                                          <div class="sub-head">Taksacja</div>
                                        </b-col>
                                        <b-col md="12" class="mt-0 text-left">
                                          <b-form-group class="tax-radio">
                                            <b-form-radio-group v-model="medItem.payment">
                                              <b-form-radio @change="OnChange(medItem)"
                                                :disabled="disableItems.disablePayment"
                                                v-for="(el, index) in medItem.taksacja" :key="'payment' + index"
                                                :value="getPaymentValue(el)">{{ el.refundation }}
                                                <span v-if="el.description">
                                                  - {{ el.description }}</span><span v-if="el.age">
                                                  - {{ el.age.toLowerCase() }}
                                                  {{ $t('visit.yearOld') }}</span></b-form-radio>
                                            </b-form-radio-group>
                                          </b-form-group>
                                          <b-form-group :label="$t('visit.notReplace')">
                                            <b-form-checkbox @change="OnChange(medItem)"
                                              :disabled="disableItems.disableReplace" v-model="medItem.notReplace"
                                              :key="medItem.ean + 'notReplace'"></b-form-checkbox>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </b-col>
                                </b-row>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-overlay>
          </div>
        </b-col>
        <!-- second block -->
      </b-row>
    </div>
    <!-- check side bar -->
    <!-- check side bar -->
    <template #modal-footer>
      <b-row style="width:100%">
        <b-col class="text-left">
          <b-button variant="primary" class="text-left" @click="resetForm">{{ $t('visit.resetFormBtn') }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button variant="secondary" @click="hideModal">{{ $t('visit.cancel') }}</b-button>
          <b-button class="ml-3" variant="primary" :disabled="disableIssuePres" @click="issuePres()">{{
            $t('visit.issuePresBtn') }}</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import moment from 'moment'
import medicineApi from '../../../services/medicines'
import prescriptionApi from '../../../services/prescriptionApi'
import { mapState } from 'vuex'
import VueTagsInput from '@johmun/vue-tags-input'

// import axios from 'axios
export default {
  components: {
    VueTagsInput
  },
  data() {
    return {
      medStateArray: [],
      medResultSuccess: false,
      expandMed: false,
      childReady: false,
      deleteMedItem: false,
      disableItems: { disableQty: false, disableDose: false, disablePayment: false, disableReplace: false, disableClose: false },
      prescStatusList: [],
      disableIssuePres: false,
      showProgress: false,
      refundedOnly: false,
      addprevMed: false,
      tag: '',
      tag1: '',
      tag2: '',
      tags: [],
      selectedFav: [],
      favOptions: [
        { text: this.$t('visit.showPatientRelated'), value: 'patientFav' },
        { text: this.$t('visit.showDoctorRelated'), value: 'doctorFav' }
      ],
      presStatus: [
        { status: null, message: '', name: 'prescription 1' },
        { status: null, message: '', name: 'prescription 2' }
      ],
      blockFullView: true,
      firstBlockSize: 0,
      secondBlockSize: 11,
      showInfo: false,
      medInfo: null,
      prescriptionJson: {
        is365: false,
        prescriptionType: 'Rp',
        dateFrom: '',
        dateTo: ''
      },
      // prescription Logic
      // prescriptionPackages: [
      //   {
      //     name: 'Prescription 1',
      //     medicaments: []
      //   },
      //   {
      //     name: 'Prescription 2',
      //     medicaments: []
      //   }
      // ],
      prescriptionPackages: [
        {
          name: this.$t('visit.presTitle') + '1',
          medicaments: []
        }
      ],
      medArray: [],
      maxPrescriptionsAllowed: 6,
      maxMedicinePerPrescription: 5,
      prescriptionCount: 0,
      count: 0,
      // prescription logic
      medicineSearchData: null,
      parsedData: [],
      prevMedicines: [],
      patientFavTags: [],
      doctorFavTags: [],
      autocompleteItems: [],
      selectedQunatity: null,
      selectedApraisal: null,
      apraisalOptions: [
        { value: '100', text: '100%' },
        { value: '50', text: '50%' },
        { value: '30', text: '30%' }
      ],
      rightsOptions: [
        {
          text: 'Żadne',
          value: null
        },
        {
          'text': 'Pracownicy i byli pracownicy zakładów produkujących wyroby zawierające azbest.',
          'value': 'AZ'
        },
        {
          'text': 'Inwalidzi wojenni oraz osoby represjonowane, ich małżonkowie pozostający na ich wyłącznym utrzymaniu, wdowy i wdowcy po poległych żołnierzach i zmarłych inwalidach wojennych oraz osoby represjonowane, uprawnione do renty rodzinnej, a także cywilne niewidome ofiary działań wojennych.',
          'value': 'IB'
        },
        {
          'text': 'Inwalidzi wojskowi.',
          'value': 'IW'
        },
        {
          'text': 'Żołnierze, którzy odbywają zasadniczą służbę wojskową, przeszkolenie wojskowe, ćwiczenia wojskowe oraz pełnią służbę kandydacką, służbę przygotowawczą lub służbę wojskową w razie ogłoszenia mobilizacji i w czasie wojny',
          'value': 'PO'
        },
        {
          'text': 'Pacjenci inni niż ubezpieczeni, którzy mają prawo do bezpłatnych świadczeń opieki zdrowotnej.',
          'value': 'IN'
        },
        {
          'text': 'Żołnierze zawodowi, o których mowa w przepisach ustawy o służbie wojskowej żołnierzy zawodowych, w trakcie szkoleń poligonowych i ćwiczeń wojskowych (rejsów, lotów).',
          'value': 'WP'
        }],
      selectedMedicines: [], // Must be an array reference!
      patientFavOptions: [],
      selectedPatientFav: null,
      doctorFavOptions: [],
      selectedDoctorFav: null,
      filters: {
        search: ''
      },
      prescriptionTypes: [
        {
          value: 'Rp',
          text: 'Rp'
        },
        { value: 'Rpw', text: 'Rpw' }
      ],
      doses: ['1x1', '2x1', '3x1'],
      quantity: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      loadingMedicines: false,
      medicines: [],
      fullYearPrescription: false,
      prescription: {
        medicaments: [],
        is365: false,
        prescriptionType: 'Rp',
        password: '',
        additionalRights: null
      },
      prescriptionModel: {
        medicaments: [],
        is365: false,
        prescriptionType: 'Rp',
        password: '',
        dateFrom: '',
        dateTo: '',
        additionalRights: null
      },
      withPayment: false,
      today: moment().format('YYYY-MM-DD'),
      showAllMedicaments: false
    }
  },
  watch: {
    tag: 'initItems'
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('Patient', {
      patient: (state) => state.patient
    }),
    placeholderText: () => 'Search Medicine',
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        if (i) {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
        }
      })
    },
    ...mapState('Patient', {
      patient: (state) => state.patient,
      showPasswordInput: (state) => !state.hasCertPassword
    }),
    ...mapState('Visit', {
      savedVisitId: (state) => state.savedVisitId
    }),
    filteredMedicaments() {
      return this.withPayment
        ? this.medicines.filter((el) => el.taksacja.length > 1)
        : this.medicines
    },
    isTeryt() {
      return this.patient.teryt || this.prescription.teryt
    },
    isAnyStrongMedicament() {
      return this.prescription.medicaments.some((el) => el.strong)
    },
    groupedMedicaments() {
      return this.filteredMedicaments.reduce(
        (prev, curr) => {
          if (curr.prio) {
            prev.priority.push(curr)
          } else {
            prev.rest.push(curr)
          }
          return prev
        },
        { priority: [], rest: [] }
      )
    }
  },
  async mounted() {
    await this.getPatientFavourites()
    await this.getDoctorFavourites()
    await this.getPrevMedicines()
    this.setPrevMedicineOption()
    this.$emit('ready')
  },
  methods: {
    parsePayment(_medItem) {
      if (_medItem.payment.indexOf('R:') >= 0) {
        const itemInfo = _medItem.taksacja.find(itm => itm.id.toString() === _medItem.payment.split(':')[1])
        return itemInfo.refundation + '-' + itemInfo.description
      }
      return _medItem.payment
    },
    parseMedsData(meds) {
      let perUnit = ''
      const strengths = meds.strengths.split(' + ').map(s => {
        const stripped = s.split('/')[0].trim().replace('(', '').replace(')', '')
        if (s.indexOf('/') >= 1) {
          perUnit = s.split('/')[1].trim()
        }
        return stripped
      })

      const substances = meds.substances.split(', ')
      const result = []
      for (let i = 0; i < substances.length; i++) {
        const composition = strengths[i] ? `${substances[i]} : ${strengths[i]}` : substances[i]
        result.push({ composition })
      }
      if (perUnit !== '') perUnit = 'per ' + perUnit
      return { comps: result, perUnit }
    },
    OnChange(item) {
      let matchedIndex = this.medStateArray.findIndex(function (medItem) {
        return (medItem.ean === item.ean)
      })
      if (matchedIndex === -1) {
        this.medStateArray.push(item)
      } else {
        this.medStateArray[matchedIndex] = item
      }
      this.parsePayment(item)
    },

    togglePres() {
      this.expandMed = !this.expandMed
      this.prescriptionPackages.forEach((item) => {
        item.medicaments.forEach((medItem) => {
          if (this.expandMed) {
            medItem['collapse'] = true
          } else {
            medItem['collapse'] = false
          }
        })
      })
    },
    resetForm() {
      this.showProgress = false
      this.prescriptionPackages = [{ name: this.$t('visit.presTitle') + '1', medicaments: [] }]
      this.medArray = []
      this.disableIssuePres = false
      this.disableItems = { disableQty: false, disableDose: false, disablePayment: false, disableReplace: false, disableClose: false }
      this.prescStatusList = []
    },
    retryPres(presIndex) {
      this.$bvModal.hide('error-modal')
      this.sendSinglePres(presIndex)
    },
    async handleViewDetails(prescriptionId) {
      this.showProgress = true
      this.receptyData = {
        id: prescriptionId,
        title: 'Prescription Details - ' + prescriptionId
      }
      let response = await prescriptionApi.downloadPrescription(prescriptionId)
      let blob = new Blob([response.data], { type: 'application/pdf' })
      const blobUrl = window.URL.createObjectURL(blob)
      this.showProgress = false
      window.open(blobUrl)
    },
    async sendSinglePres(presIndex) {
      this.showProgress = true
      let prCtr = presIndex + 1
      const presItem = this.prescriptionJson.prescriptionPackages[presIndex]
      const payload = {
        v: '2.1',
        is365: this.prescriptionJson.is365,
        prescriptionType: this.prescriptionJson.prescriptionType,
        password: this.prescriptionJson.password,
        dateFrom: this.prescriptionJson.dateFrom,
        dateTo: this.prescriptionJson.dateTo,
        visitId: this.$route.params.visit,
        medicaments: [...presItem.medicaments],
        patientId: this.patient.id
      }
      const prescStatus = {
        hasError: true,
        errorDetail: '',
        userMessage: ''
      }
      if (payload.medicaments.length > 0) {
        const response = await prescriptionApi.sendPrescription(
          payload,
          this.$route.params.visit
        )
        const errorText = this.getPropFromJSONObject(
          response,
          'opisProblemu'
        )
        const presIssued = this.getPropFromJSONObject(
          response,
          'kluczPakietuRecept'
        )
        if (errorText != null) prescStatus.warning = errorText
        if (errorText != null && presIssued == null) {
          // update the status of error
          prescStatus.errorDetail = errorText
          prescStatus.userMessage = `Failed to issue prescription : ${prCtr}`
        } else {
          prescStatus.hasError = false
          prescStatus.receptyId = this.getPropFromJSONObject(response, 'prescriptionId')
          prescStatus.userMessage = `Prescription : ${prCtr} created successfully.`
          this.getPrevMedicines()
          // show success message
        }
        this.makeToast(
          prescStatus.hasError ? 'danger' : 'success',
          prescStatus.userMessage,
          'Status'
        )

        this.prescStatusList[presIndex] = prescStatus
        const errorDetails = this.prescStatusList.filter((f, idx) => idx === presIndex).map(p => p.userMessage + ' : ' + p.errorDetail + ' ').join('')
        if (prescStatus.hasError) {
          this.$swal(
            this.$t('error'),
            this.$t('visit.addPrescriptionError') + ' ' + errorDetails,
            'error'
          )
        } else {
          const addlText = `wynik Weryfikacji Zbioru Regul : ` + prescStatus.warning
          this.$swal(
            this.$t('success'),
            this.$t('visit.madeRecipeSuccess') + ' ' + addlText,
            'success'
          )
        }
        this.showProgress = false
      }
    },
    changePayment(checked) {
      this.$refs.tagInput.$refs.newTagInput.focus()
      this.initItems()
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('"dddd, MMMM Do YYYY, h:mm:ss a"')
      }
    },
    getPropFromJSONObject(jsonData, propName) {
      let str = JSON.stringify(jsonData)
      let r = new RegExp(`${propName}":"([^"]{1,})"`)
      let res = r.exec(str)
      return res && res.pop()
    },
    async changeFavourites(event) {
      let selected = event[event.length - 1]
      if (
        selected === 'patientFav' &&
        this.selectedFav.indexOf('doctorFav') !== -1
      ) {
        this.selectedFav.shift()
      } else if (
        selected === 'doctorFav' &&
        this.selectedFav.indexOf('patientFav') !== -1
      ) {
        this.selectedFav.shift()
      }
    },
    async issuePres() {
      this.showProgress = true
      if (!this.isTeryt) {
        // show message
        this.makeToast('danger', 'Invalid Teryt', 'Error!')
        return 0
      }

      this.disableItems = { disableQty: true, disableDose: true, disablePayment: true, disableReplace: true, disableClose: true }
      this.disableIssuePres = true
      this.prescriptionJson['prescriptionPackages'] = this.prescriptionPackages

      try {
        let prCtr = 1
        this.prescStatusList = []
        for (let presItem of this.prescriptionJson.prescriptionPackages) {
          const payload = {
            v: '2.1',
            is365: this.prescriptionJson.is365,
            prescriptionType: this.prescriptionJson.prescriptionType,
            password: this.prescriptionJson.password,
            dateFrom: this.prescriptionJson.dateFrom,
            dateTo: this.prescriptionJson.dateTo,
            visitId: this.$route.params.visit,
            medicaments: [...presItem.medicaments],
            patientId: this.patient.id
          }
          const prescStatus = {
            hasError: true,
            errorDetail: '',
            userMessage: ''
          }
          if (payload.medicaments.length > 0) {
            const response = await prescriptionApi.sendPrescription(
              payload,
              this.$route.params.visit
            )
            const errorText = this.getPropFromJSONObject(
              response,
              'opisProblemu'
            )
            const presIssued = this.getPropFromJSONObject(
              response,
              'kluczPakietuRecept'
            )
            if (errorText != null && presIssued == null) {
              // update the status of error
              prescStatus.errorDetail = errorText
              prescStatus.userMessage = `Failed to issue prescription : ${prCtr}`
            } else {
              prescStatus.hasError = false
              if (errorText != null) prescStatus.warning = errorText
              prescStatus.receptyId = this.getPropFromJSONObject(response, 'prescriptionId')
              prescStatus.userMessage = `Prescription : ${prCtr} created successfully.`
              this.getPrevMedicines()
              // show success message
            }
            this.makeToast(
              prescStatus.hasError ? 'danger' : 'success',
              prescStatus.userMessage,
              'Status'
            )

            this.prescStatusList.push(prescStatus)
            prCtr++
          }
        }

        this.showProgress = false
        if (this.prescStatusList.filter((f) => f.hasError === true).length > 0) {
          const errorDetails = this.prescStatusList.filter(f => f.hasError === true).map(p => p.userMessage + ' : ' + p.errorDetail + ' ').join('')
          this.$swal(
            this.$t('error'),
            this.$t('visit.addPrescriptionError') + ' ' + errorDetails,
            'error'
          )
        } else {
          const warnings = this.prescStatusList.filter((f) => f.hasError === false).map((p, ctr) => parseInt(ctr + 1) + '. ' + p.warning + ' ').join('')
          const addlText = `wynik Weryfikacji Zbioru Regul : ` + warnings
          this.$swal(
            this.$t('success'),
            this.$t('visit.madeRecipeSuccess') + ' ' + addlText,
            'success'
          )
          // show final summary and what needs to be done next
          this.$emit('refresh', {})
        }
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.addPrescriptionError'),
          'error'
        )
      } finally {
        this.showProgress = false
      }
    },
    getPresStatus(index, status, msg) {
      if (this.prescriptionPackages[index].medicaments.length !== 0) {
        this.presStatus[index].status = status
        this.presStatus[index].message = msg
      }
    },
    makeToast(variant = null, message, titleText) {
      let autoHideMilliSecs = variant === 'success' ? 2000 : 6000
      this.$bvToast.toast(message, {
        // title: `Variant ${variant || 'default'}`,
        title: titleText,
        variant: variant,
        autoHideDelay: autoHideMilliSecs,
        toaster: 'b-toaster-top-center'
      })
    },
    checkTag(obj) {

    },
    update(newTags) {
      this.autocompleteItems = []
      this.tags = newTags
    },
    initItems() {
      if (this.tag.length < 2) return
      this.medResultSuccess = false
      // var items
      this.debounce = setTimeout(async () => {
        try {
          clearTimeout(this.debounce)

          const { data } = await medicineApi.getMedicines(
            this.tag,
            this.refundedOnly
          )

          this.autocompleteItems = data.data.groups.map((a) => {
            // return { text: a.groupName, id: a.groupId, matchedArray: a.items }
            const items = this.withPayment
              ? a.items.filter((el) => el.taksacja.length > 1)
              : a.items
            return {
              text: a.groupName,
              id: a.groupId,
              groupType: a.groupType,
              matchedArray: items
            }
          })
          this.medResultSuccess = true
        } catch (e) {
          // console.warn('Oh. Something went wrong')
        }
      }, 900)
    },
    includeSelectedMedToPres() {
      // this.selectedMedicines.forEach((id) => {
      //   this.addMedToPresByEan(id)
      // })

      this.addprevMed = true
      this.selectedMedicines.forEach((value) => {
        //
        // selectedItem = []
        for (var i = 0; i < this.prevMedicines.length; i++) {
          let selectedItem = this.prevMedicines[i].medicines.filter((medItem) => medItem.keyValue === value)

          if (selectedItem.length !== 0) {
            this.addMedToPresByEan(selectedItem[0].ean, { quantity: selectedItem[0].quantity, dose: selectedItem[0].dose, payment: selectedItem[0].refund })
            break
          }
        }
      })
      this.selectedMedicines = []
    },
    reuseMedicine(medicineList) {
      // medicineList.forEach((item) => {
      //   if (this.selectedMedicines.indexOf(item.ean) === -1) {
      //     this.selectedMedicines.push(item.ean)
      //   }
      // })
      // this.selectedMedicines.forEach((id) => {
      //   this.addMedToPresByEan(id)
      // })
      this.addprevMed = true
      medicineList.forEach((item) => {
        this.addMedToPresByEan(item.ean, { quantity: item.quantity, dose: item.dose, payment: item.refund })
        // this.addMedicineToPres(item)
      })
    },
    async addMedToPresByEan(id, addMedDetails) {
      try {
        const { data } = await medicineApi.getMedicineDetails(id)

        if (addMedDetails && data.data.items.length !== 0) {
          this.addMedicineToPres({ ...data.data.items[0], ...addMedDetails })
        } else {
          this.addMedicineToPres(data.data.items[0])
        }
      } catch (e) { }
    },
    async addToPatientFavourites(item) {
      let payload = {
        ean: item.ean,
        name: item.medicineName
      }
      try {
        await medicineApi.saveFavourites(this.patient.id, payload)
      } catch (e) { }
      this.getPatientFavourites()
    },
    async removeFromPatientFavorites(item) {
      try {
        await medicineApi.deleteFav(this.patient.id, { ean: item.ean })
      } catch (error) { }
      this.selectedPatientFav = null
      this.getPatientFavourites()
    },
    async addToDoctorFavourites(item) {
      let itemName = (item.medicineName.indexOf('mg') === -1) ? (item.medicineName + ' | ' + item.strength + ' | ' + item.packageSize + item.unit) : (item.medicineName + ' | ' + item.packageSize + item.unit)
      // item.favourites = true
      let payload = {
        ean: item.ean,
        name: itemName
      }
      try {
        await medicineApi.saveFavourites('', payload)
        this.makeToast('success', `${item.medicineName} Added to favourites`, 'Success!')
      } catch (e) { }
      this.getDoctorFavourites()
    },
    async removeFromDoctorFavorites(item) {
      try {
        this.doctorFavOptions = this.doctorFavOptions.filter(
          (f) => f.ean !== item.ean
        )
        await medicineApi.deleteFav('', { ean: item.ean })
        this.makeToast('success', `${item.name} removed from favourites`, 'Success!')
      } catch (error) { }
      this.selectedDoctorFav = null
      this.getDoctorFavourites()
    },
    closeSuggestBox() {
      this.parsedData.items = []
      this.tags = []
    },
    closeInfo() {
      this.showInfo = false
      this.medInfo = null
    },
    showMedInfo(item) {
      this.blockFullView = false
      this.firstBlockSize = 4
      this.secondBlockSize = 8
      this.showInfo = true
      this.medInfo = {}
      setTimeout(() => {
        this.medInfo = item
      }, 10)
    },
    toggleSidebar() {
      this.blockFullView = !this.blockFullView
      if (this.blockFullView) {
        this.firstBlockSize = 0
        this.secondBlockSize = 11
      } else {
        this.firstBlockSize = 4
        this.secondBlockSize = 8
      }
    },
    hideModal() {
      this.$refs['pres-modal'].hide()
    },
    async getPatientFavourites() {
      try {
        const { data } = await medicineApi.getFavourites(this.patient.id)
        this.patientFavOptions = data.data.items
      } catch (e) { }
    },
    async getDoctorFavourites() {
      try {
        const { data } = await medicineApi.getFavourites('')
        this.doctorFavOptions = data.data.items
      } catch (e) { }
    },
    setPrevMedicineOption() {
      this.prevMedicines.forEach((item) => {
        item.medicines.forEach((medItem) => {
          medItem['text'] = medItem.name
          medItem['value'] = medItem.keyValue
        })
      })
    },
    async getPrevMedicines() {
      try {
        const { data } = await medicineApi.getPrevMedicine(this.patient.id)
        this.prevMedicines = data.data.items
      } catch (e) { }
    },
    async getMedicines(params) {
      try {
        const { data } = await medicineApi.getMedicines(params)
        this.parsedData = data.data
      } catch (e) { }
    },
    formatPresciptionData() {
      this.prescriptionPackages = [{ name: this.$t('visit.presTitle') + '1', medicaments: [] }]
      this.count = 0
      // if (this.medArray.length === 2) {
      //   this.medArray[0].strong = 0
      //   this.medArray[1].strong = 1
      // }
      let strongMedicine = this.medArray.filter((item) => item.strong === 1)
      let normalMedicine = this.medArray.filter((item) => item.strong === 0)
      //
      this.formatNormalMedicine(normalMedicine)
      this.formatStrongMedicine(strongMedicine)

      // store state values
      this.medStateArray.forEach((stateItem) => {
        this.prescriptionPackages.forEach((item) => {
          item.medicaments.forEach((medItem, index) => {
            if (stateItem.ean === medItem.ean) {
              item.medicaments[index] = stateItem
            }
          })
        })
      })
      // store state values
    },
    formatStrongMedicine(strongMedicine) {
      // if (strongMedicine.length > 2) { return }
      for (let j = 0; j < strongMedicine.length; j++) {
        if (this.prescriptionPackages[this.count].medicaments.length !== 0) {
          this.count++
          this.prescriptionPackages.push({ name: this.$t('visit.presTitle') + (this.count + 1), medicaments: [] })
        }
        if (strongMedicine[j].ean === this.medArray[this.medArray.length - 1].ean) {
          strongMedicine[j]['collapse'] = true
        } else {
          strongMedicine[j]['collapse'] = false
        }
        if (this.addprevMed) {
          this.prescriptionPackages[this.count].medicaments.push(strongMedicine[j])
        } else {
          this.prescriptionPackages[this.count].medicaments.push({
            ...strongMedicine[j],
            dose: '1x1',
            quantity: '1',
            payment: '100%',
            notReplace: false
          })
        }
        this.tag = ''
        if (strongMedicine[j].ean === this.medArray[this.medArray.length - 1].ean && !this.deleteMedItem) {
          let successMessage = 'The medicine ' + strongMedicine[j].medicineName + ' successfully added to the prescription'
          this.makeToast('success', successMessage, 'Success!')
          this.addprevMed = false
          this.deleteMedItem = false
        }
      }
      //
    },
    formatNormalMedicine(normalMedicine) {
      for (let j = 0; j < normalMedicine.length; j++) {
        if (this.prescriptionPackages[this.count].medicaments.length > this.maxMedicinePerPrescription - 1 && this.count < this.maxPrescriptionsAllowed) {
          this.count++
          this.prescriptionPackages.push({ name: this.$t('visit.presTitle') + (this.count + 1), medicaments: [] })
        }
        if (normalMedicine[j].ean === this.medArray[this.medArray.length - 1].ean) {
          normalMedicine[j]['collapse'] = true
        } else {
          normalMedicine[j]['collapse'] = false
        }
        if (this.addprevMed) {
          this.prescriptionPackages[this.count].medicaments.push(normalMedicine[j])
        } else {
          this.prescriptionPackages[this.count].medicaments.push({
            ...normalMedicine[j],
            dose: '1x1',
            quantity: '1',
            payment: '100%',
            notReplace: false
          })
        }
        this.tag = ''
        if (normalMedicine[j].ean === this.medArray[this.medArray.length - 1].ean && !this.deleteMedItem) {
          let successMessage = 'The medicine ' + normalMedicine[j].medicineName + ' successfully added to the prescription'
          this.makeToast('success', successMessage, 'Success!')
          this.deleteMedItem = false
        }
        this.addprevMed = false
      }
    },
    addMedicineToPres(medDetails) {
      if (this.disableIssuePres === true) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.disablePresErrorMsg'),
          'error'
        )
        return
      }
      //
      let alreadyExists = this.medArray.filter(function (medItem) {
        return (medItem.ean === medDetails.ean)
      }).length > 0
      if (alreadyExists) {
        let warningMessage = medDetails.medicineName + ' already added in the prescription list'
        this.makeToast('danger', warningMessage, 'Error!')
        return
      } else {
        this.medArray.push(medDetails)
      }
      if (!medDetails && medDetails.length === 0) return
      this.formatPresciptionData()
    },
    deleteMed(medItem) {
      this.deleteMedItem = true
      let deleteIndex = this.medArray.findIndex((item) => item.ean === medItem.ean)
      let deletestateIndex = this.medStateArray.findIndex((stateItem) => stateItem.ean === this.medArray[deleteIndex].ean)
      this.medStateArray.splice(deletestateIndex, 1)
      this.medArray.splice(deleteIndex, 1)
      this.formatPresciptionData()
      let successMessage = 'The medicine ' + medItem.medicineName + ' has successfully deleted from the prescription'
      this.makeToast('danger ', successMessage, 'Success!')
    },
    async searchMedicines() {
      try {
        this.loadingMedicines = true
        this.showAllMedicaments = false
        const { data } = await medicineApi.get(this.filters)
        this.medicines = data.medicines
      } catch (e) {
      } finally {
        this.loadingMedicines = false
      }
    },
    resetData() {
      this.prescription = this.prescriptionModel
    },
    addMedicine(item) {
      this.prescription.medicaments.push({
        ...item,
        dose: '1x1',
        quantity: '1',
        payment: '100%',
        notReplace: false
      })
    },
    deleteMedicine(i) {
      this.prescription.medicaments.splice(i, 1)
    },
    getDate() {
      return moment().format('YYYY-MM-DD HH:mm')
    },
    handleShowAllMedicaments() {
      this.showAllMedicaments = true
    },
    async copyPrescription(prescriptionId) {
      try {
        const data = await prescriptionApi.getPrescriptionCopy(prescriptionId)
        this.prescription = {
          ...this.prescription,
          ...data
        }
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.copyPrescriptionError'),
          'error'
        )
      }
    },
    sendPrescription(e) {
      try {
        if (this.isTeryt) {
          this.$emit('add', {
            ...this.prescription,
            visitId: this.$route.params.visit,
            patientId: this.patient.id
          })
          this.resetData()
        } else {
          e.preventDefault()
        }
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.addPrescriptionError'),
          'error'
        )
      }
    },
    getPaymentValue(payment) {
      if (payment.refundation === '100%') {
        return payment.refundation
      }
      return `${payment.refundation}:${payment.id}`
    }
  }
}
</script>
<style lang="scss">
.prescription-table td:first-child {
  min-width: 140px;
  padding-right: 10px;
}

.prescription-table td {
  padding: 5px 0px;
}

.when-closed {
  display: inline;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}

.accor-actions svg path {
  fill: #6c757d;
}

.accor-actions svg path:hover {
  fill: #5a6268;
}

.collapsed>.accor-opened,
:not(.collapsed)>.accor-closed {
  display: none;
}

.medicine-card {
  border: 1px solid #bbb;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}

.taxation-list {
  list-style-type: disc;
}

.ti-tag.blue {
  background-color: blue !important;
}

.ti-tag.orange {
  background-color: orange !important;
}

.ti-tag.green {
  background-color: green !important;
}

.ti-tag.violet {
  background-color: violet !important;
}

.my-item {
  // border-bottom: 1px solid #6c757da1;
  color: #6c757d;
  padding: 0px 0px !important;
  text-align: left;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  .card {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.ti-autocomplete {
  // max-height: 300px;
  max-height: calc(100vh - 17rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.my-item .card-header {
  border: none;
}

.my-item div {
  padding: 2px;
  font-size: 14px;

  .sub-text {
    font-size: 13px;
  }

  .sub-text-comp {
    color: #be7f0d;
  }
}

// .patientFav {
//   padding: 0px !important;
//   font-size: 0.9rem;
//   margin-top: 0.2rem;
// }

.action {
  padding: 10px;
  background-color: green;
  color: white;
  margin-left: 10px;
}

.my-item span {
  padding: 5px;
  margin: 5px 0px;
  font-size: 14px;
}

.ti-selected-item {
  background-color: rgb(229, 220, 220) !important;
}

.vue-tags-input {
  max-width: unset !important;
}

.sub-head {
  font-weight: bold;
  color: #495057c9;
}

.modal-title {
  width: 100%;
}

.pres-header.card {
  border: none;

  .card-body {
    padding-top: 12px;
  }
}

.suggestion-box {
  position: absolute;
  width: 93%;
  height: 100%;
  // height: 40px;
  // background-coloR: red;
  z-index: 1000;
  // background-color: #fff;

  .cont {
    background-color: #ffff;
    border: 1px solid #6c757da1;
  }
}

.tags-input {
  width: 93%;
}

.tags-auto-input {
  width: 90%;
}

.pres-header .card-header {
  background-color: #80808040;
}

.check-icon.b-icon.bi {
  vertical-align: middle;
}

.pres-sub-header .card-header {
  background-color: #6c757d17;

  .info:hover {
    fill: #067a86;
  }

  .close-icon:hover {
    fill: #c82333;
  }
}

// .strong-med,
// .strong-med-bg {
//   border: 1px solid #ffcccc !important;
// }
.pres-header .card-header.strong-med-bg {
  background-color: #ffcccc;
}

.strong-med {
  border: 2px solid #ffcccc;
  border-top: none
}

.info-box {
  position: absolute;
  height: 100%;
  width: 100%;
  height: calc(100vh - 17rem);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  z-index: 1060;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.8);
  // padding: 20px 15px;
  top: 0px;
  margin-top: 0px;
  left: 0px;
  // animation: fadeIn 10s;
}

.b-icon.bi.star-icon {
  vertical-align: text-top !important;
}

span.plus-icon,
span.minus-icon {
  display: inline-block;
  height: 17px;
  width: 17px;
  border: 1px solid gray;
  padding: 0px;
  margin: 0px;
  text-align: center;
  line-height: 12px;
  font-weight: bold;
}

.trans-first-block.col-md-0 {
  max-width: 0px;
  width: 0px;
  opacity: 0;
}

.trans-first-block.col-md-4 {
  opacity: 1;
}

.trans-container .row [class*='col-'] {
  transition: all 0.5s ease-in-out;
}

.modal-dialog-scrollable .modal-body {
  // min-height: 300px
  min-height: calc(100vh - 17rem);
}

// .b-icon {
//   cursor: pointer;
// }

.hide-block {
  opacity: 0 !important;
  transition: all 0.5s ease-in-out;
}

.info-header {
  background-color: #e9ecef;
}

.med-description {
  line-height: 1.8;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-body {
  padding: 0px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.content-block {
  color: #6c757d;
}

.info-box .title {
  font-weight: 500;
  color: #495057;
}

.med-actions .btn {
  padding: 5px 8px;
  outline: none;
  border: none
}

.med-actions .b-icon:hover,
.med-actions svg path:hover {
  fill: #fff;
}

.head-arrows .b-icon:hover {
  fill: #5a6268;
}

.close-icon .b-icon:hover {
  fill: #c82333;
}

.hide-info {
  display: none;
}

.show-info {
  display: block;
}

.switch-control .form-group {
  margin-bottom: auto;
}

.switch-control .custom-switch {
  font-size: 14px;
}

.modal-body {
  font-size: 14px;
}

.modal-body .btn {
  font-size: 14px;
  font-weight: 500;
}

.seperator-border {
  border-top: 1px solid lightgray;
}

.custom-check .custom-control-label {
  vertical-align: text-bottom;
  font-size: 0.9rem;
  align-items: left;
  margin-left: 0;
  font-weight: normal;
}

.med-info {
  vertical-align: text-top;
}

.pres-title {
  font-weight: 500;
}

.doc-fav {
  fill: #17a2b8;
  vertical-align: revert
}

.med-row {
  border-bottom: 1px solid #eee;
}

// .med-row{
//   .b-icon:hover, .doc-fav:hover{
//      fill: #fff;
//   }
//   .btn:hover{
//     color:white;
//   }
// }
.med-row:hover {
  background-color: #089bab;

  .b-icon:not(.star-icon) {
    fill: white
  }

  .btn {
    border: none;
  }

  span {
    color: #fff;
  }

  svg path {
    fill: #fff;
  }

  .doc-fav {
    fill: #fff !important;
  }

  .custom-icon {
    background-color: #fff;
    color: #089bab;
  }

  .custom-icon.star {
    color: #ffc107;
  }
}

.cursor-point {
  cursor: pointer;
}

.head-container,
.trans-container {
  font-size: 14px;
}

.ti-new-tag-input-wrapper {
  font-size: 14px !important;
}

.ti-new-tag-input-wrapper input {
  font-size: 14px !important;
}

.tax-radio {
  .custom-control-inline {
    display: block;
  }
}

.custom-icon {
  padding: 0px !important;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #089bab;
  color: #fff;
  border-radius: 50%;
  font-weight: 500;
  margin: 7px !important;
}

.custom-icon.star {
  color: #ffc107;
}

.custom-icon:hover {
  background-color: #d6d8db !important;
  color: #089bab !important;
}

.custom-icon.star:hover {
  color: #ffc107 !important;
}

.vue-multiselect {
  .multiselect__option--selected.multiselect__option--highlight {
    background: #089bab !important;
  }

  .multiselect__option--selected.multiselect__option--highlight::after {
    background: #089bab !important;
  }

  .multiselect__option--selected {
    font-weight: normal;
  }

  .multiselect__option {
    font-size: 14px;
    line-height: 18px;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-size: 14px;
  }
}

.med-search-header {
  position: sticky;
  top: 0px;
  background-color: #f4f2f2 !important;
  z-index: 1;
  border-bottom: 1px solid #adb5bd;
}

.type2 {
  background-color: #d6d8db !important;
}

.expand-collapse-block:hover {
  color: #17a2b8 !important
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  position: absolute;
  top: 20px;
  right: 22px;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.green {
  background: #66FF99;
}

.orange {
  background: #f17c57fa;
}
</style>
