import axios from './index'

export default {
  async getSpeechCaption(visitId, patientId) {
    try {
      const { data } = await axios.get(
        `/api/auth/aiservices/${patientId}/${visitId}/caption`
      )
      return data
    } catch (e) {
      throw new Error(e)
    }
  },
  async getSummaryPrompts(visitId, patientId) {
    try {
      const { data } = await axios.get(
        `/api/auth/aiservices/${patientId}/${visitId}/prompts`
      )
      return data
    } catch (e) {
      throw new Error(e)
    }
  },
  async summarize(payload, visitId) {
    try {
      const { data } = await axios.post(
        '/api/auth/aiservices/' + visitId + '/summarize',
        payload
      )
      return data
    } catch (e) {
      throw new Error(e)
    }
  },
  async logEvent(payload) {
    try {
      const { data } = await axios.post(
        '/api/auth/aiservices/events',
        payload
      )
      return data
    } catch (e) {
      throw new Error(e)
    }
  }
}
